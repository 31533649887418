
// this file will be included once.
// use to define custom styles.

@import '../../../shared/variables';
@import 'global/theme_imports'; // found in libs/common/theme/frontend
@import '../../../shared/styles';


h3 		{ color:var(--primaryColor) }

.layout-topbar.inline {
	&				{ background:transparent; }
}
